<template>
  <div class="coinbase-container">
    <el-card :body-style="{ padding: '0 20px' }">
      <div slot="header">
        <span>即将上链</span>
      </div>
      <!-- <div class="last-coinbase">ID：{{ lastCoinbase.id }}</div> -->
      <div class="last-coinbase">内容：{{ lastCoinbase }}</div>
      <!-- <div class="last-coinbase">金额：{{ lastCoinbase.order_amount }}</div> -->
      <!-- card body -->
    </el-card>

    <el-table :data="coinbaseList.items" border stripe>
      <el-table-column
        v-for="col in columns"
        :key="col.id"
        :prop="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="!!(scope.row.audited || scope.row.isOnChain)"
            @click="handleAudit(scope.row)"
            >确认审核</el-button
          >
          <el-button
            size="mini"
            type="warning"
            :disabled="!(scope.row.audited && !scope.row.isOnChain)"
            @click="handleAuditCancel(scope.row)"
            >取消审核</el-button
          >
          <el-button
            size="mini"
            type="danger"
            :disabled="!scope.row.isOnChain"
            @click="handleReset(scope.row)"
            >重置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 40, 80, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="coinbaseList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  data() {
    return {
      columns: [
        {
          id: "id",
          label: "ID",
        },
        {
          id: "username",
          label: "昵称",
        },
        {
          id: "avatar_url",
          label: "头像",
          formatter: (row) => {
            return <img src={`${row.avatar_url}`} height="60px" width="60px" />;
          },
        },
        {
          id: "content",
          label: "内容",
        },
        {
          id: "block_height",
          label: "区块高度",
        },
        {
          id: "block_hash",
          label: "区块hash",
        },
        {
          id: "isOnChain",
          label: "是否上链",
          formatter: (row) => {
            if (row.isOnChain) {
              return <el-tag type="success">已上链</el-tag>;
            }
            return <el-tag type="warning">未上链</el-tag>;
          },
        },
        {
          id: "audited",
          label: "审核状态",
          formatter: (row) => {
            if (row.audited) {
              return <el-tag type="success">已审核</el-tag>;
            }
            return <el-tag type="warning">未审核</el-tag>;
          },
        },
        {
          id: "status",
          label: "支付状态",
          formatter: (row) => {
            switch (+row.status) {
              case 0:
                return <el-tag type="warning">未支付</el-tag>;
              case 1:
                return <el-tag type="success">已支付</el-tag>;
              case -1:
                return <el-tag type="info">取消支付</el-tag>;
              case -2:
                return <el-tag type="danger">支付失败</el-tag>;
              default:
                return row.status;
            }
          },
        },
        {
          id: "order_amount",
          label: "金额",
        },
        {
          id: "create_time",
          label: "创建时间",
          formatter: (row) =>
            dayjs(row.create_time).format("YYYY-MM-DD hh:mm:ss"),
        },
      ],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapGetters(["coinbaseList", "lastCoinbase"]),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.getCoinbaseList();
      this.$store.dispatch("coinbase/getLastCoinbase");
    },
    // 审核
    handleAudit(item) {
      this.update(item.id, { audited: 1 });
    },
    // 取消审核
    handleAuditCancel(item) {
      this.update(item.id, { audited: 0 });
    },
    // 重置
    handleReset(item) {
      this.$confirm("会重置审核和上链, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.update(item.id, { audited: 0, isOnChain: 0 });
      });
    },
    update(id, data) {
      this.$store.dispatch("coinbase/putCoinbase", {
        id: id,
        data,
      });
    },
    getCoinbaseList() {
      this.$store.dispatch("coinbase/getCoinbaseList", {
        page: this.currentPage,
        page_size: this.pageSize,
      });
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getCoinbaseList();
    },
    currentChange(page) {
      this.currentPage = page;
      this.getCoinbaseList();
    },
  },
};
</script>

<style lang="less" scoped>
.coinbase-container {
  padding: 20px;

  .el-card {
    width: 300px;
    margin-bottom: 20px;
    text-align: left;
  }

  .last-coinbase {
    margin: 20px 0;
    font-size: 14px;
  }

  .el-pagination {
    padding: 20px;
    text-align: right;
  }
}
</style>
